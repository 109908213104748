import "./Home.css";

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import {GrInstagram, GrLinkedinOption} from "react-icons/gr"
import React, { useContext, useEffect, useState } from 'react';

import Flowers from "../Media/HomePage/Flowers.jpg";
import alley from "../Media/HomePage/Alley.jpg";
import bridge from "../Media/HomePage/Bridge.jpg";
import euroWall from "../Media/HomePage/EuroWall.jpg";
import landing from "../Media/HomePage/LandingImage.png";
import pool from "../Media/HomePage/Pool.jpg";
import profilePic from "../Media/HomePage/ProfilePic.jpg";
import tableAndChairs from "../Media/HomePage/TableAndChairs.jpg";
import useWindowDimensions from '../Utils/UseWindowDimensions';
import volcano from "../Media/HomePage/Volcano2.jpg";
import wall from "../Media/HomePage/Wall.jpg";

export default function Home(props) {
    const { height, width } = useWindowDimensions();
    function calculateFontSize(){
        //exponential decay, starts font at 60px, decreases exponentially approaching 30px
        let x = 60-((width-300)/10);
        return ((30*Math.pow(Math.E,(-0.07*x)))+30);
    }

    return(
        <div>
            {/* <img className='landingImage' src={landing} title="Photo of Groningen that I took from the top of a ferris wheel, The Netherlands" alt="Photo of Groningen taken from the top of a ferris wheel, The Netherlands"></img> */}
            <section className='welcome'>
                {/* <p style={(width<900)?{fontSize:calculateFontSize()}:{fontSize:60}} className='welcomeText'>WELCOME TO <br/> TRAVEL CULTURE</p> */}
                <div className="welcomePageIntro">
                    <div className='introCardDesc'>
                        <h1 className="welcomeText">WELCOME</h1>
                        {/* These sections will be edited to make them smaller */}
                        {/* Alter for increased whitespace, */}
                        {/* Pictures and buttons need to all be standard sizes */}
                        {/* Remove annotations on hover */}
                        <p className='cardText'>Travel Culture is a boutique luxury travel advising company that offers personalized service and leverages partnerships all over the world to design one-of-a-kind itineraries tailored to you.</p>
                        <div>
                            <Link to="/how-it-works#services"><div className='cardButton'><p>LEARN MORE</p></div></Link>
                        </div>
                    </div>
                    <img src={bridge} title="Puente Nuevo in Ronda." alt="Puente Nuevo in Ronda."></img>
                </div>

                <div className='infoCard-r'>
                    <img className='cardImg' src={alley} title="Gothic quarter alley in Barcelona" alt="Gothic quarter alley in Barcelona"></img>
                    <div className='cardDesc'>
                        <h1 className='homeCardTitle'>Curated Travel Experiences</h1>
                        {/* Services oriented content will go here */}
                        <p className='cardText'>Whoever you are, there is a truly grand adventure waiting for you. I will take the time to understand every detail of your vision, design and book your custom itinerary, and support you every step of the way.</p>
                        <div>
                        <Link to="/contact"><div className='cardButton'><p>GET STARTED</p></div></Link>
                        </div>
                    </div>
                </div>
                <div className='infoCard'>
                    <div className='cardDesc'>
                        <h1 className='homeCardTitle'>Responsible Travel</h1>
                        <p className='cardText'>Travel Culture strives to make a positive impact by empowering you to forge authentic and meaningful bonds with the places you visit. Travel Culture is an affiliate of Avenue Two Travel, the first travel advising company to become B-Corp Certified</p>
                        <div>
                        <a href="https://avenuetwotravel.com/b-corp-certification/" target="_blank" rel="noopener noreferrer"><div className='cardButton'><p>LEARN MORE</p></div></a>
                        </div>
                    </div>
                    <img className='bridgeImage' src={volcano} title="View of Arenal Volcano from The Springs resort, Costa Rica" alt="View of Arenal Volcano from The Springs resort, Costa Rica"></img>
                </div>
            </section>
            <section className='MySpecialties'>

                
                <div className='specContainer'>
                    <div className='left'>
                        <img className="profile" src={profilePic}></img>
                    </div>
                    <div className='specText'>
                        <h1 className='specialtiesText'>Travel Specialties</h1>
                        <p className="test">
                            I specialize in extended experiential travel that's more than just a vacation. I like to bring enough "study abroad" energy to the trips I design that you won't be able to stop talking about them afterwards.
                        </p>
                        {/* <ul>
                            <li>
                                <Link to="/specialties#immersion"><p>Immersion Trips</p></Link>
                            </li>
                            <li>
                                <Link to="/specialties#worldTour"><p>Multi-Country</p></Link>
                            </li>
                            <li>
                                <Link to="/specialties#themedTrips"><p>Themed Trips</p></Link>
                            </li>
                        </ul> */}
                        {/* <Link to="/specialties"><div className='cardButton'><p>LEARN MORE</p></div></Link> */}
                        <div className='categoriesList'>
                            <div className="righthandLine"><Link to="/specialties#immersion"><p>Immersion Trips</p></Link></div>
                            <div className="righthandLine"><Link to="/specialties#worldTour"><p>Multi-Country</p></Link></div>
                            <div><Link to="/specialties#themedTrips"><p>Themed Trips</p></Link></div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section className='advantages'>
                <h1 className='advTitle'>Advantages of Using a Travel Advisor</h1>
                <div className='pointContainer'>
                    {/* Write code to color the title p on line break */}
                    <div className='pointBox1'>
                        <div className='headBox'>
                            <p className='pointHeading' id='g'>
                                SPECIAL
                            </p>
                            <p className='pointHeading'id='w'>
                                BENEFITS
                            </p>
                        </div>
                        <strong className='w-line'>_</strong>
                        <p className='pointText' id='w'>
                        My experience and connections unlock access to special benefits and amenities such as resort credits and complimentary breakfasts.
                        </p>
                    </div>
                    <div className='pointBox2'>
                        <div className='headBox'>
                        <p className='pointHeading'id='b'>
                                SAVE TIME
                            </p>

                        </div>
                        <strong className='line'id='b'>_</strong>
                        <p className='pointText' id='b'>
                        I have the tools, connections, and expertise to expertly plan all types of trips, saving you time, resources, and frustration.
                        </p>
                    </div>
                    <div className='pointBox3'>
                        <div className='headBox'>
                            <p className='pointHeading'id='b'>
                                PEACE
                            </p>
                            <p className='pointHeading' id='w'>
                            OF MIND 
                            </p>
                        </div>
                        <strong className='w-line'>_</strong>
                        <p className='pointText' id='w'>
                        I am there to support you and help mitigate any unexpected issues that might arise during travel, giving you peace of mind and the ability to enjoy your travel to the fullest.
                        </p>
                    </div>

                </div>
            </section>
        </div>
    )
}