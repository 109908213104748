import './MenuItems.css';

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

import Dropdown from "./Dropdown";
import logo from '../Media/logo.png';

export const rightMenuItems = [
  {
    title: 'TOOLS',
    url: '/tools',
    submenu:[
      {
      title:"BOOKING",
      url:"/tools#virtuosoBookingTool"
      },
      {
        title:"SAFETY",
      url:"/tools#safety"
      },
      {
        title:"LOGISTICS",
      url:"/tools#logistics"
      },
    ]
  },
  {
      title: 'CONTACT',
      url: '/contact',
      
  },
]

export const leftMenuItems = [
    {
      title: 'ABOUT',
      url: '/about',
      submenu:[
        {
        title:"ABOUT ME",
        url:"/about"
        },
        {
          title:"TRAVEL SPECIALTIES",
        url:"/specialties"
        },
        {
          title:"MISSION",
        url:"/mission"
        },
        
      ]
    },
    {
      title: 'HOW IT WORKS',
      url: '/how-it-works',
      submenu:[
        {
        title:"MY PROCESS",
        url:"/how-it-works#myProcess"
        },
        {
          title:"SERVICES",
        url:"/how-it-works#services"
        },
        {
          title:"AFFILIATIONS",
        url:"/affiliations"
        },
      ]
    },
  ];

  


  export const MenuItem=({items,style,toggle})=>{
    const [dropdown, setDropdown] = useState(false);

    

  
    return(
        <div style={style} className="menuItems">
            {items.submenu ? (
                <>
                    <Link
                    to={items.url}
                    className='menuLink' 
                    aria-haspopup="menu">
                    <p style={{margin:"0px"}}>{items.title}{' '}</p>
                    </Link>
                    <Dropdown submenus={items.submenu}/>
                </>
            ):(
                <Link
                to={items.url}
                className='menuLink' 
                onClick={toggle}
                aria-haspopup="menu">
                {
                  items.title === '' ? <img className="image" alt="logo of travel culture llc" src={logo}></img> : <p style={{margin:"0px"}}>{items.title}{' '}</p>
                }
                </Link>
            )}
        </div>
    )
  }