import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Link, useLocation } from "react-router-dom";
import terms from "../Media/Terms/TermsAndConditionsTravelCulture.pdf";
import "./Footer.css";

export default function Footer(props) {

    return(
        <footer>
            <p>Freya Quist</p>
            <u><a href='mailto: freya@travelculturellc.com'>freya@travelculturellc.com</a></u>
            <u><a className='terms' href={terms} download>Client Terms and Conditions</a></u>
        </footer>
    )
}