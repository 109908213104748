import './About.css';

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import {FiMap, FiMapPin} from "react-icons/fi";
import React, { useContext, useEffect, useState } from 'react';

import Affiliations from './Affiliations/Affiliations';
import {BiWine} from "react-icons/bi";
import {BsTranslate} from "react-icons/bs";
import {FaGraduationCap} from "react-icons/fa";
import {IoDiamondOutline} from "react-icons/io5";
import about1 from "../../Media/About/about1.webp";
import about2 from "../../Media/About/about2.webp";
import bio from "../../Media/About/Filtered.jpg";

export default function About(props) {

    return(
        <section className='aboutPageContainer'>
            {/* <div className='titleBackground'>
                <h1 className='title'>ABOUT ME</h1>
            </div> */}
            <div className='bioCard'>
                <div className='titleAlign'>
                    <div className='matchWidth'></div>
                    
                </div>
               
                <div className='imageContainer'>
                    <div className='picDiv'>
                        <img className='bioPic' src={bio} />
                    </div>
                    <div className='bioText'>
                        <div className='squish'>
                            <p className='bioTitle'>Freya Quist</p>
                            <p className='bioSubTitle'>Travel Advisor, Owner</p>
                            <p className='bioContent'>I am all for big moods and bold moves. I think that novel experiences are the best way to grow as a person and my travel philosophy reflects that. I value challenging my ideas and expectations while abroad. A month of Mandarin classes in the heart of Taipei, working remotely from sunny Costa Rica during the winter, taking a whole summer to travel around Europe by train... I think at some point everyone feels like they’ve just got to get out there, and all it takes is a good plan to make it happen.
                            </p>
                            <p className='bioContent'>
                            I am a research-loving, detail-oriented compulsive nomad (how could I not be? The world is so cool!). My 15 cumulative years of experience living abroad aid in the creation of my clients’ complex and often lengthy itineraries. For me, travel is about more than just making memories or finding a good deal; it’s about making the world a smaller, more familiar place. I love what I do because it’s my job to help people find magic and adventure wherever they go.
                            </p>
                        </div>
                    </div>

                </div>
                
                
            </div>
            <section className='funFacts'>
                <h1 className='sectionTitle'>Fun Facts</h1>
                <div className='ffGridContainer'>
                    <div className='ffRow'>
                        <div className='ffCard'>
                            <FiMap size={30}/>
                            <h3 className='ffTitle'>Dream Trip</h3>
                            <p className='ffContent'>Adult gap-year in southeast asia (or anywhere, really).</p>
                        </div>
                        <div className='ffCard'>
                            <BiWine size={30}/>
                            <h3 className='ffTitle'>Go-To Drink</h3>
                            <p className='ffContent'>Depends on where I am in the world. I like to embrace the "when in Rome" philosophy.</p>
                        </div>
                    </div>
                    <div className='ffRow'>
                        <div className='ffCard'>
                            <FaGraduationCap size={30}/>
                            <h3 className='ffTitle'>Education</h3>
                            <p className='ffContent'>MA in international politics from the Johns Hopkins School of Advanced International Studies (SAIS) in Nanjing.</p>
                        </div>
                        <div className='ffCard'>
                            <BsTranslate size={30}/>
                            <h3 className='ffTitle'>Languages</h3>
                            <p className='ffContent'>I speak English, Spanish, and Mandarin. I'm currently working on Italian and French.</p>
                        </div>
                    </div>
                    <div className='ffRow'>
                        <div className='ffCard'>
                            <IoDiamondOutline size={30}/>
                            <h3 className='ffTitle'>Why Luxury?</h3>
                            <p className='ffContent'>I mean, who doesn't like nice things?</p>
                        </div>
                        <div className='ffCard'>
                            <FiMapPin size={30}/>
                            <h3 className='ffTitle'>Where To Next?</h3>
                            <p className='ffContent'>I'm currently living in Taipei. Maybe France next?</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='advantages'>
        <h1 className='advTitle'>ADVANTAGES OF USING A TRAVEL ADVISOR</h1>
        <div className='pointContainer'>
            {/* Write code to color the title text on line break */}
            <div className='pointBox1'>
                <div className='headBox'>
                    <text className='pointHeading' id='g'>
                        SPECIAL
                    </text>
                    <text className='pointHeading'id='w'>
                        BENEFITS
                    </text>
                </div>
                <strong className='w-line'>_</strong>
                <text className='pointText' id='w'>
                My experience and connections unlock access to special benefits and amenities such as resort credits and complimentary breakfasts.
                </text>
            </div>
            <div className='pointBox2'>
                <div className='headBox'>
                <text className='pointHeading'id='b'>
                        SAVE TIME
                    </text>

                </div>
                <strong className='line'id='b'>_</strong>
                <text className='pointText' id='b'>
                I have the tools, connections, and expertise to expertly plan all types of trips, saving you time, resources, and frustration.
                </text>
            </div>
            <div className='pointBox3'>
                <div className='headBox'>
                    <text className='pointHeading'id='b'>
                        PEACE
                    </text>
                    <text className='pointHeading' id='w'>
                    OF MIND 
                    </text>
                </div>
                <strong className='w-line'>_</strong>
                <text className='pointText' id='w'>
                I am there to support you and help mitigate any unexpected issues that might arise during travel, giving you peace of mind and the ability to enjoy your travel to the fullest.
                </text>
            </div>

        </div>
    </section>

        </section>
    )
}