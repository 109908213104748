import "./Mission.css";

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

import bannerImg from "../../Media/Services/Poas.jpg";
import bridgeImg from "../../Media/Services/SpainBridge.jpg";
import useWindowDimensions from '../../Utils/UseWindowDimensions';

export default function Mission(props) {

    const { height, width } = useWindowDimensions();
    function calculateFontSize(){
        //exponential decay, starts font at 60px, decreases exponentially approaching 30px
        //Change font to 48
        let x = 60-((width-300)/10);
        return ((30*Math.pow(Math.E,(-0.07*x)))+30);
    }

    return(
        <div className='missionPage'>
            {/* Double back fonts on this page. */}
            <div className='bannerContainer'>
                <img className='banner'src={bridgeImg}></img>
                <section className='mission'>
                    <div className='bannerTextBackground'>
                        <h1 style={(width<900)?{fontSize:calculateFontSize()}:{fontSize:60}} className='bannerTitle'>MISSION</h1>
                        {/* <p className='bannerText'>MISSION</p> */}
                    </div>
                    <div className='opacity'>
                        <p className='missionTitle'>Travel Culture's mission is to make the world a smaller place; to foster global community by empowering you to meaningfully engage with people, culture, and the environment.</p>
                        <p className='missionText'>
                            Travel Culture is a full-service travel advising company that designs and coordinates one-of-a-kind trips for the aspiring global citizen. 
                        </p>
                        <p className='missionText'>
                            So much can be accomplished when you travel with intention. New languages, foods, connections, stories, and experiences; interesting people, customs, and traditions; art, shopping, architecture, business; drama and intrigue… there is a whole world out there, so live in it. No plan? No problem. Whoever you are, there is a truly grand adventure waiting for you. I'll help you find it and support you every step of the way.
                        </p>
                        <p className='missionText'>
                            Every Travel Culture journey is customized to meet your goals and vision. Every international adventure is unique, and I understand the practical aspects and the amount of support required to make them happen. I start by getting to know you, your travel companions, and your desires and aspirations for your journey; as well as answer any questions you may have for me. 
                        </p>
                        <p className='missionText'>
                            Once we agree to work together, I create a thorough itinerary that's designed to meet your specific needs, goals, and wishes by taking into account important factors such as the weather, international events, time zones, language, cultural environments, political climates, animal migration patterns, and anything else that might be central to your vision. Travel Culture makes all transportation, lodging, and touring arrangements. I act as a constant resource before and during your travel in an advisory capacity, and strive give you the inside track wherever you are with information that lends depth to your experience.
                        </p>
                    </div>
                </section>
            </div>
            
            <div className='quoteContainer'>
            
                    <img className='quoteImg'src={bannerImg}></img>
                    <div className='quoteBackground'>
                        <p className='quoteText'>I love the world, and nothing makes me happier than helping people find magic in it.</p>
                    </div>
            </div>
        </div>
    )
}