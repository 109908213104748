import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Link, useLocation } from "react-router-dom";
import "./Contact.css";

export default function Contact(props) {

    return(
        // To do, check form resize for mobile
        <div>
            <div className='titleCard'>
                    <p className='title'>LET'S TALK TRAVEL</p>
                    <p className='titleText'>Please email me at <a href='mailto: freya@travelculturellc.com'>freya@travelculturellc.com</a> with any quick questions about working with Travel Culture.</p>
                    <p className='titleText'>OR</p>  <p className='titleText'>If you're ready to get started, take a few minutes to fill out the Trip Design Form below so that I can start getting to know you and your travel style. I look forward to connecting with you!</p>
                </div>
            <div className='formContainer'>
                
                <p className='formTitle'>Trip Design Form</p>
                <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.5/iframeResizer.min.js"></script>
                <iframe src="https://traveljoy.com/webforms/EDCg9HhpEbRJqnVAsinKnN8g/forms/aFBrD5Y4pDtYizFhBatFPaXz" frameBorder="0" width="100%" height="2850">
                </iframe>
            </div>
        </div>
    )
}