import './App.css';
import "./Fonts/LuloCleanOneBold.otf";

import NavContextProvider, {useNavContext} from "./Utils/NavContext";
import React, { useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import About from './Pages/About/About';
import Affiliations from './Pages/About/Affiliations/Affiliations';
import Contact from './Pages/Contact';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Pages/Home';
import HowItWorks from './Pages/Services/HowItWorks';
import Mission from './Pages/About/Mission';
import Offsites from './Pages/Offsites/Offsites';
import PreTravel from './Pages/Tools/PreTravel';
import Terms from './Pages/Tools/Terms';
import TravelSpecialties from './Pages/About/TravelSpecialties/TravelSpecialties';
import birdPic from "./Media/Tools/Birb.jpg";
import logo from './logo.svg';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from './Utils/UseWindowDimensions';

function App() {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [menuState,setMenuState]=useState(false);
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);



  function handleMainClick(){
    setMenuState(false);
  }

  return (
      //Have fun with this one.
      <div className="App" style={(location.pathname=="/tools")?((width>1200)?{backgroundImage: `url(${birdPic})`,backgroundSize:"100%",backgroundPosition:"bottom -312px center",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}:{backgroundImage: `url(${birdPic})`,backgroundSize:"auto auto",backgroundPositionY:"0px",backgroundPositionX:"50%",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}):{backgroundImage: null}}>
        <Header setExternalMenuTrue={()=>{setMenuState(true)}} externalMenuState={menuState} className="header"/>
        <main onClick={handleMainClick}>
          <Routes>
            <Route 
              path='/'
              element={<Home/>}
            />
            <Route 
              path='/about'
              element={<About/>}
              
            />
            <Route 
              path='/about-6'
              element={<TravelSpecialties/>}
            />
            <Route 
              path='/specialties'
              element={<TravelSpecialties/>}
            />
            <Route 
              path='/basic-01-1'
              element={<Affiliations/>}
            />
            
            <Route 
              path='/affiliations'
              element={<Affiliations/>}
            />
            <Route 
              path='/mission'
              element={<Mission/>}
            />
            <Route 
              path='/how-it-works'
              element={<HowItWorks/>}
            />
            <Route 
              path='/services'
              element={<HowItWorks/>}
            />
            <Route 
              path='/tools'
              element={<PreTravel/>}
            />
            <Route 
              path='/terms-and-conditions'
              element={<Terms/>}
              
            />
            <Route 
              path='/contact'
              element={<Contact/>}
            />
            <Route 
              path='/copy-of-contact'
              element={<Contact/>}
            />
            <Route 
              path='/about-7'
              element={<PreTravel/>}
            />
            <Route 
              path='/offsites'
              element={<Offsites/>}
            />
          </Routes>
        </main>
        
      </div>
    
  );
}

export default App;
