import './Dropdown.css';

import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Dropdown menu generator that takes a object with a   * title and url
 * @param {[string]} submenus items to display
 * @return {Element} react element
 */
function Dropdown({submenus}) {
  return (
    <ul className="dropdown">
      {submenus.map((submenu, index) => (
        <li style={{listStyle:"none"}} key={index}>
          <Link to={submenu.url} className="dropdownContent">
            {submenu.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
Dropdown.propTypes = {
  submenus: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  })),
};

export default Dropdown;
