import "./Affiliations.css";

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

import A2 from "../../../Media/Partners/AvenueTwoNavy.png";
import Accor from "../../../Media/Partners/Partnered brands/AccorStep.png";
import Belmond from "../../../Media/Partners/Partnered brands/BelmondBelliniClub.png";
import Coture from "../../../../src/Media/Partners/Partnered brands/CoutureByLangham.png";
import Diamond from "../../../../src/Media/Partners/Partnered brands/DiamondClub.png";
import Doyle from "../../../../src/Media/Partners/Partnered brands/DoyleCollection.png";
import FourSeasons from "../../../../src/Media/Partners/Partnered brands/FourSeasons.png";
import Hyatt from "../../../../src/Media/Partners/Partnered brands/HyattPrive.png";
import IHG from "../../../../src/Media/Partners/Partnered brands/IHGLuxury.png";
import Imp from "../../../../src/Media/Partners/Partnered brands/Impresario.webp";
import Jumeriah from "../../../../src/Media/Partners/Partnered brands/Jumeriah.png";
import Mandarin from "../../../../src/Media/Partners/Partnered brands/MandarinOrientalFanClub.png";
import Mariott from "../../../../src/Media/Partners/Partnered brands/LuminousMarriott.png";
import Oetker from "../../../../src/Media/Partners/Partnered brands/Oetker.png";
import Peninsula from "../../../../src/Media/Partners/Partnered brands/ThePeninsula.png";
import Pref from "../../../../src/Media/Partners/Partnered brands/Preferred.png";
import Rocco from "../../../../src/Media/Partners/Partnered brands/RoccoForteKnights.png";
import Rosewood from "../../../../src/Media/Partners/Partnered brands/Rosewood.png";
import SLH from "../../../../src/Media/Partners/Partnered brands/SLHWithIN.png";
import ShangriLa from "../../../../src/Media/Partners/Partnered brands/shangri-la.png";
import Viceroy from "../../../../src/Media/Partners/Partnered brands/Viceroy.png";
import Virtuoso from "../../../Media/Partners/VirtuosoMonochrome.png";
import useWindowDimensions from '../../../Utils/UseWindowDimensions';

export default function Affiliations(props) {
    const { height, width } = useWindowDimensions();

    function calculateFontSize(){
        //exponential decay, starts font at 60px, decreases exponentially approaching 30px
        let x = 60-((width-300)/10);
        return ((30*Math.pow(Math.E,(-0.07*x)))+30);
    }

    return(
        <section className='affiliations'>
            {/* UPDATE LOGO VERSIONS */}
            <p className='subTitle' style={(width<900)?{fontSize:calculateFontSize()}:{fontSize:60}}>AFFILIATIONS & PREFERRED PARTNERSHIPS</p>
            <div className='partnerContainer'>
                <a className='cardLink' target="_blank" href={"https://avenuetwotravel.com/advisor/Freya-Quist/"} rel="noreferrer">
                    <div className='partnerCard'>
                        <img className='prtnrImg' src={A2}></img>
                        <p className='partnerDesc'>
                            Travel Culture is a proud affiliate of Avenue Two Travel, an internationally-recognized provider of unique travel experiences. Drawing on the vast experience and impressive knowledge of Avene Two's group of elite travel advisors is a fantastic resource Travel Culture uses to deliver inspiring and rewarding travel experiences. 
                        </p>
                        <div className='cardButton'><text>LEARN MORE</text></div>
                    </div>
                </a>
                <div className='remSpace'></div>
                <a className='cardLink' target="_blank" href={"https://www.virtuoso.com/advisor/freyquis11458/travel"} rel="noreferrer">
                    <div className='partnerCard'>
                        <img className='prtnrImg' src={Virtuoso}></img>
                        <p className='partnerDesc'>
                            Avenue Two Travel is a member of Virtuoso, the world's leading network of travel professionals. Virtuoso is an invitation-only organization, and grants us privileged access to exclusive amenities, hotel offers, and one-of-a-kind travel experiences that are otherwise unobtainable.
                        </p>
                        <div className='cardButton'><text>LEARN MORE</text></div>
                    </div>
                </a>
            </div>
            <div className='gridHead'><h1 className='headText'>Working with travel culture gives you privileges with these and many more luxury travel partners:</h1></div>
            <div className='gridContainer'>
                <div className='gridItem'><img className='brndImg' src={FourSeasons}></img></div>
                <div className='gridItem'><img className='brndImg' src={SLH}></img></div>
                <div className='gridItem'><img className='brndImg' src={Accor}></img></div>
                <div className='gridItem'><img className='brndImg' src={Belmond}></img></div>
                <div className='gridItem'><img className='brndImg' src={Pref}></img></div>
                <div className='gridItem'><img className='brndImg' src={Mandarin}></img></div>
                
                <div className='gridItem'><img className='brndImg' src={Diamond}></img></div>
                <div className='gridItem'><img className='brndImg' src={Rosewood}></img></div>
                
                
                <div className='gridItem'><img className='brndImg' src={Hyatt}></img></div>
                <div className='gridItem'><img className='brndImg' src={Rocco}></img></div>
                <div className='gridItem'><img className='brndImg' src={ShangriLa}></img></div>
                <div className='gridItem'><img className='brndImg' src={Imp}></img></div>
                
                <div className='gridItem'><img className='brndImg' src={Jumeriah}></img></div>
                <div className='gridItem'><img className='brndImg' src={Mariott}></img></div>
                <div className='gridItem'><img className='brndImg' src={Coture}></img></div>
                <div className='gridItem'><img className='brndImg' src={Oetker}></img></div>
                <div className='gridItem'><img className='brndImg' src={Doyle}></img></div>
                
                
                <div className='gridItem'><img className='brndImg' src={Peninsula}></img></div>
                <div className='gridItem'><img className='brndImg' src={Viceroy}></img></div>
                <div className='gridItem'><img className='brndImg' src={IHG}></img></div>
                
                
            </div>
        </section>
    )
}