import "./HowItWorks.css"

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

import bluePots from "../../Media/HowItWorks/BluePots.jpg"
import brownPots from "../../Media/HowItWorks/BrownPots.jpg"
import paintedPots from "../../Media/HowItWorks/PaintedPots.jpg"
import volcano from "../../Media/HowItWorks/VolcanoFromBed.jpg"

export default function HowItWorks(props) {

    return(
        <div className='howItWorksPage'>
        <div className="specPageIntro">
            <img src={volcano}/>
            <div className="spiText">
                <h1>HOW IT WORKS</h1>
            </div>
        </div>
        <section id='myProcess' className='theProcess'>
        <h1 className='processTitle'>The Process</h1>
                <div className='processCard'>
                    <p className='stepTitle'>CONNECT</p>
                    <p className='stepText'>I get to know my clients personally. Who are you? What are you passionate about? Why is traveling important to you? Getting to know you allows me to curate experiences that will be meaningful and design an itinerary that is tailored to you.</p>
                </div>
                <div className='processCard'>
                    <p className='stepTitle'>DESIGN</p>
                    <p className='stepText'>We'll work together to make sure the details are just right. How will the time zone affect your remote work schedule? Do you prefer a first class train cabin or a private car? Do you have a pathological aversion to asparagus due to a traumatic childhood experience? No detail is too small.</p>
                </div>
                <div className='processCard'>
                    <p className='stepTitle'>CONFIRM</p>
                    <p className='stepText'>Once you are satisfied with my itinerary proposal, I will take care of booking everything and organize your reservation confirmations and tickets in the Avenue Two travel app for easy access.</p>
                </div>
                <div className='processCard'>
                    <p className='stepTitle'>EXPERIENCE</p>
                    <p className='stepText'>During travel, I act as a resource for you. I can make dining, theater, or spa reservations; find you a language tutor, a meeting room, or the secret entrance to the speakeasy; and help you navigate anything unexpected that may come up.</p>
                </div>
                <div className='processCard'>
                    <p className='stepTitle'>REPEAT!</p>
                    <p className='stepText'>I'm doing my job right if you want to start brainstorming your next adventure the second you get back.</p>
                </div>

                
        </section>
        {/* Aug 11th: Revisit images */}
        <div id="services" className="picBanner">
            <div className="potPic">
                <img className="noPosition" src={bluePots}/>
            </div>
            <div className="potPic">
            <img className="noPosition" src={brownPots}/>
            </div>
            <div className="potPic">
            <img className="noPosition" src={paintedPots}/>
            </div>
        </div>
        
        <section className='servicesPage'>
            <p id="servicesTitle" className='processTitle'>Services</p>
            <div className='servicesDisplay'>
                <div className='serviceCard'>
                    <h2>Airline Ticketing & Management</h2>
                    <p className='servicesText'>Travel Culture has an amazing Air Team to handle all flights for your itinerary.</p>
                    <hr className="liLine"/>
                    <i className='servicesBullet'>Expert research on best options</i>
                    <hr className="liLineButTiny"/>
                    <i className='servicesBullet'>Stress free booking ticket management</i>
                    <hr className="liLineButTiny"/>
                    <i className='servicesBullet'>24/7 emergency resources</i>
                </div>
                <div className='serviceCard'>
                    <h2>Custom Itinerary Design & Booking</h2>
                    <p className='servicesText'>After taking the time to fully understand your vision, I work with my trusted local partners to create your custom itinerary.</p>
                    <hr className="liLine"/>
                    <i className='servicesBullet'>Personalized itinerary proposal(s)</i>
                    <hr className="liLineButTiny"/>
                    <i className='servicesBullet'>Booking for all accomodation, transportation, and activities</i>
                    <hr className="liLineButTiny"/>
                    <i className='servicesBullet'>Trip details easily accessible via mobile app</i>

   
                </div>
                <div className='serviceCard'>
                    <h2>Support & Concierge Services</h2>
                    <p className='servicesText'>
                        Travel Culture is a full-service agency, and support continues even after booking. Travel seamlessly:   
                    </p>
                    <hr className="liLine"/>

                    <i className='servicesBullet'>Visa application support, Luggage forwarding, Insider tips.</i>
                    <hr className="liLineButTiny"/>
                    <i className='servicesBullet'>Spa/Dining/Theatre reservations</i>
                    <hr className="liLineButTiny"/>
                    <i className='servicesBullet' >Spontaneous plan changes</i>
                    {/* 
                    Visa application support
                    Luggage forwarding
                    Entry rules and regulations
                    Be in the know
                    Travel seamlessly: Visa application support, Luggage forwarding, Insider tips.
                     */}
                </div>
            </div>
        </section>
        <section className='quote'>
            <p className='quoteTitle'>Fees</p>
            <p className='quoteText'>Travel Culture charges a research & design fee for personalized itineraries.<br/><br/>Custom quote only.</p>
            <div className='linkAlign'><Link to="/contact"><div className='cardButton'><text>CONTACT</text></div></Link></div>

        </section>
        </div>
    )
}