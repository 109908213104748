import "./PreTravel.css";

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

import birdPic from "../../Media/Tools/Birb.jpg";
import useWindowDimensions from '../../Utils/UseWindowDimensions';

export default function PreTravel(props) {

    const { height, width } = useWindowDimensions();

    return(
        <div className='toolsPage'>
            <section className='titleSection' id='wbgn'>
                <div className='titleContainer'>
                    <h1 style={(width<900)?{fontSize:40}:{fontSize:60}}>Tools</h1>
                    <p>Stress-free travel is all about the right prep and tools. Travel like a pro with these tried and true resources aimed at creating the smoothest possible travel exprience.</p>
                </div>
            </section>
            <section id='safety' className='safetySection'>
                <div className='titleContainer'>
                    <p style={(width<900)?{fontSize:40}:{fontSize:60}} className='cardTitle'>Safety Resources</p>
                    <div className="itemContainer">
                        <div className='toolItem'>
                            <h2>State Department Alerts</h2>
                            {/* Reword for uniform size */}
                            <p className='toolText'>The Smart Traveler Enrollment Program (STEP) is a free service for U.S. citizens and nationals traveling abroad. You can enroll your trip with the nearest U.S. Embassy or Consulate to receive safety information and resources in case of emergency.</p>
                            
                            <div className='space'></div>
                            <div className='btnContainer'>
                                <a rel="noreferrer" target="_blank" href={"https://step.state.gov/step/"}><div className='cardButton'><text>Learn More</text></div></a>
                            </div>
                        </div>
                        <div className='toolItem'>
                            <h2>Entry Requirements</h2>
                            <p className='toolText'>Sherpa° is a great tool for understanding current country-specific rules and regulations, including visa and health requirements.</p>
                            <div className='space'></div>
                            <div className='btnContainer'>
                                <a rel="noreferrer" target="_blank" href={"https://apply.joinsherpa.com/travel-restrictions?affiliateId=cal&language=en-US"}><div className='cardButton'><text>Learn More</text></div></a>
                            </div>
                            
                        </div>

                    </div>
                </div>
                <div className='opacity'  id="lgry"></div>
            </section>
            <section id='logistics' className='logisticSection'>
                <div className='titleContainer'>
                    <p style={(width<900)?{fontSize:40}:{fontSize:60}} className='cardTitle'>Logistics</p>
                    <div className="itemContainer">
                        <div className='toolItem'>
                            <h2>Clear</h2>
                            <p className='toolText'>Have the smoothest departure experience at the airport. Clear lets you speed though security; no need to even show ID!</p>
                            <div className='space'></div>
                            <div className='btnContainer'>
                                <a rel="noreferrer" target="_blank" href={"https://www.clearme.com"}><div className='cardButton'><text>Learn More</text></div></a>
                            </div>
                        </div>
                        <div className='toolItem'>
                            <h2>Global Entry</h2>
                            <p className='toolText'>Global Entry speeds up the process at customs after you return to the U.S. from an international destination.</p>
                            <div className='space'></div>
                            <div className='btnContainer'>
                                <a rel="noreferrer" target="_blank" href={"https://www.cbp.gov/travel/trusted-traveler-programs/global-entry"}><div className='cardButton'><text>Learn More</text></div></a>
                            </div>
                        </div>
                        <div className='toolItem'>

                            <h2>Luggage Free</h2>
                            <p className='toolText'>Avoid the inconvenience of carrying, checking and claiming luggage. Ship it so it's ready and waiting for you at your destination!</p>
                            <div className='space'></div>
                            <div className='btnContainer'>
                                <a rel="noreferrer" target="_blank" href={"https://www.app.luggagefree.com/avenue-two-travel"}><div className='cardButton'><text>Learn More</text></div></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='opacity'  id="lgry"></div>
            </section>
            <section id='virtuosoBookingTool' className='virtuosoSection' >
                
                    <div className='titleContainer'> 
                        <p style={(width<900)?{fontSize:40}:{fontSize:60}} className='cardTitle'>Virtuoso Booking Tool</p>
                        <div className="itemContainer">
                            <div className='toolItem'>
                                {/* On mobile, keep button */}  
                                <div id="mobileVirtuosoBtn" className='btnContainer'>
                                    <a rel="noreferrer" target="_blank" href={"https://www.virtuoso.com/advisor/freyquis11458/travel"}><div className='cardButton'><text>Learn More</text></div></a>
                                </div>
                                <div className="rowToColumn" >
                                    <div className="toolBullets">
                                        <h2>What it is:</h2>
                                        <p className='toolText'>Travel Culture is partnered with the finest hotels and travel providers around the world. If you enjoy researching and booking your own hotels but you'd still like to access Travel Culture's VIP perks and ameneties, check out the Virtuoso Booking Tool.</p><p className='toolText'> This is perfect for DIY travelers or last-minute bookings. Travel Culture will still recieve the details of your booking, and just like always, personally reach out to the hotel to communicate your   preferences.</p>
                                        <div id="desktopVirtuosoBtn" className='btnContainer'>
                                            <a rel="noreferrer" target="_blank" href={"https://www.virtuoso.com/advisor/freyquis11458/travel"}><div className='cardButton'><text>Learn More</text></div></a>
                                        </div>
                                    </div>
                                    <div className="toolBullets" id="leftMargin" >
                                    <h2>Things to know:</h2>
                                    <ul style={{textAlign:"left"}}>
                                        <li>
                                        Virtuoso.com is a self-service luxury hotel booking tool, so it's <b>great for last-minute getaways and/or after-hours bookings.</b>
                                        </li>
                                        <li>
                                        <b>Not all of Travel Culture's partner properties are available on the site.</b> If you're looking for something in particular and aren't finding it, please don't hesitate to reach out.
                                        </li>
                                        <li>
                                        Virtuoso is one of many exclusive avenues available to Travel Culture. <b>Occasionally, we will have a better deal</b>   than what can be found on Virtuoso.com. Where possible, it is always strategic to touch base to ensure you're getting the best product out there.
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='opacity'  id="lgrn"></div>
                </section>
        </div>
    )
}