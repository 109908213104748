import './Header.css';

import {BrowserRouter, Link, Route, useLocation} from 'react-router-dom';
import {MenuItem, leftMenuItems, rightMenuItems} from './MenuItems';
import NavContextProvider, {useNavContext} from '../Utils/NavContext';
import React, {useContext, useEffect, useRef, useState} from 'react';

import {AiOutlineMenu} from 'react-icons/ai';
import navbarLogo from '../Media/Navbar/NavbarLogo.png';
import useWindowDimensions from '../Utils/UseWindowDimensions';

export default function Header(props) {
  const location = useLocation();
  const [menuShown, setMenuShown]=useState({display: 'flex'});
  const [menuState, setMenuState]=useState(true);
  const clickedMenu = useRef(false);
  const {height, width} = useWindowDimensions();
  const [menuIconSize, setMenuIceonSize] = useState(50);
  const [menuIconDisplay, setMenuIceonDisplay] = useState(false);
  const [headerOffset, setHeaderOffset]=useState(0);
  const ref = useRef(null);
  const {externalMenuState, setExternalMenuTrue} = props;
  const ul = useRef(null);
  useEffect(()=>{
    if (width<800) {
      setMenuState(!menuState);
      setMenuShown({display: getMenuStyleString(!menuState)});
    }
    window.addEventListener('scroll', handleScroll);
  }, []);
  useEffect(()=>()=>{
    window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(()=>()=>{
    if (externalMenuState) {
      toggleMenu();
    }
  }, [externalMenuState]);
  useEffect(()=>{
    if (width<800) {
      setMenuShown({display: getMenuStyleString(false)});
      setMenuIceonDisplay(true);
      if (headerOffset==-50) {
        setHeaderOffset(-330);
      }
    } else {
      setMenuShown({display: getMenuStyleString(true)});
      setMenuState(false);
      setMenuIceonDisplay(false);
      if (headerOffset==-330) {
        setHeaderOffset(-50);
      }
    }
  }, [width]);


  const toggleMenu = ()=>{
    if (menuState) {
      setMenuIceonSize(40);
      setExternalMenuTrue();
    } else {
      setMenuIceonSize(50);
    }
    setMenuState(!menuState);
    setMenuShown({display: getMenuStyleString(menuState)});
  };
  function getMenuStyleString(b) {
    if (b) {
      return ('inline-block');
    } else {
      return ('none');
    }
  }
  function desktopNavBar(){
    return <>
     {leftMenuItems.map((menu, index)=>{
        return (
          <div key={`left${index}`} className='navItemContainer'>
            {menuIconDisplay?
              <MenuItem toggle={toggleMenu} items={menu} key={index} style={menuShown}/> :
              <MenuItem items={menu} key={index} style={menuShown}/>
            }
          </div>
        );
      })
      }
      <Link to={"/"} className="navLogo"><img src={navbarLogo}/></Link>
      {rightMenuItems.map((menu,index)=>{
        return (
          <div key={`right${index}`} className='navItemContainer'>
            {menuIconDisplay?
              <MenuItem toggle={toggleMenu} items={menu} key={index} style={menuShown}/> :
              <MenuItem items={menu} key={index} style={menuShown}/>
            }
          </div>
        );
      })}
    </>
  }

  const handleScroll=()=>{
    if (window.innerWidth<720) {
      if (document.documentElement.scrollTop<50) {
        setHeaderOffset(-1*document.documentElement.scrollTop);
      } else {
        setHeaderOffset(-50);
      }
    } else {
      if (document.documentElement.scrollTop<50) {
        setHeaderOffset(-1*document.documentElement.scrollTop);
      } else {
        setHeaderOffset(-50);
      }
    }
  };
  // if(!props.show)
  // return null;
  return (
    <header style={{top: `${headerOffset}px`}}>
      <div className='headerContainer'>
        <div className='headerBar'/>
        {/* <div className='logo'>
          <Link style={{display:"flex",width:"auto"}} to='/'>
          <img className="logoImg" alt="logo of travel culture llc" src={logo}></img>
          </Link>
        </div> */}

        <div className='nav'>
            {width<800 && <Link to={"/"} onClick={()=>{
              if(menuState){
                toggleMenu();
              }
            }} style={{width:"60%",height:"100%"}}><img className='mobileLogo' src={navbarLogo}></img></Link>}
            {menuIconDisplay?<li className='mobileButton' onClick={toggleMenu} key="mobileButton"><AiOutlineMenu size={menuIconSize}/></li>:null}

            {width > 800 && desktopNavBar()}


        </div>
      </div>
      {menuIconDisplay && menuState && 
        <div className="mobileMenu" onClick={()=>{
          if(!clickedMenu.current){
            setMenuState(false)
          }
          clickedMenu.current = false;
        }}>
          <ul onClick={()=>{
            clickedMenu.current = true;
          }}>
            <li><Link to={"/about"} onClick={toggleMenu}>About</Link></li>
            <ul>
              <li><Link to={"/about"} onClick={toggleMenu}>About Me</Link></li>
              <li>
                <Link to={"/specialties"} onClick={toggleMenu}>Travel Specialties</Link>
              </li>
              <li><Link to={"/mission"} onClick={toggleMenu}>Mission</Link></li>
            </ul>
            <li><Link to={"/how-it-works"} onClick={toggleMenu}>How it Works</Link></li>
            <ul>
              <li><Link to={"/how-it-works#myProcess"} onClick={toggleMenu}>My Process</Link></li>
              <li><Link to={"/how-it-works#services"} onClick={toggleMenu}>Services</Link></li>
              <li><Link to={"/affiliations"} onClick={toggleMenu}>Affiliations</Link></li>
            </ul>
            <li><Link to={"/tools"} onClick={toggleMenu}>Tools</Link></li>
            <ul>
              <li><Link to={"/tools#virtuosoBookingTool"} onClick={toggleMenu}>Booking</Link></li>
              <li><Link to={"/tools#safety"} onClick={toggleMenu}>Safety</Link></li>
              <li><Link to={"/tools#logistics"} onClick={toggleMenu}>Logistics</Link></li>
            </ul>
            <li><Link to={"/Contact"} onClick={toggleMenu}>Contact</Link></li>
          </ul>
          
        </div>
      }
    </header>
  );
}
