import "./Offsites.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from "react-responsive-carousel";
import React from 'react';
import Valley from "../../Media/Offsites/Valley.jpg"
import beach from "../../Media/Offsites/beach.jpg"
import bridge2 from "../../Media/Offsites/Bridge2.jpg"
import indoorRetreat from "../../Media/Offsites/indoorRetreat.jpg"
import stairs from "../../Media/Offsites/Stairs.jpg"
import yoga from "../../Media/Offsites/Yoga.jpg"

export default function Offsites(props) {


    return (
        <section className="offsitesPage">
            <section className="offsitesIntroduction">
                <div className="offsitesDescription">

                    <div style={{marginBottom:"0",display:"flex",alignItems:'center'}}>
                        <p className="offsitesTitle" style={{marginBottom:"0"}}>COMPANY OFFSITES</p>
                    </div>
                    <p className='cardText'>Travel Culture is a boutique travel advising company that offers personalized service and leverages partnerships all over the world to design offsites and retreats tailored to your small team.</p>
                    <p className="hoverTitle" style={{marginBottom:"0"}}>INCLUSIONS</p>
                    <ul>
                        <li>Initial discovery call</li>
                        <li>Detailed destination/accommodation proposal (up to three options to compare)</li>
                        <li>Booking: lodging, flights, any additional transportation, activities, travel insurance, etc.</li>
                        <li>Direct communication with every team member to gather their necessary traveler details</li>
                        <li>Travel documents and other important information delivered to each traveler</li>
                        <li>Trip management (payments, requests, confirmations, reminders)</li>
                        <li>Pre-departure call (if desired)</li>

                        <li>Support during travel from me and my local partners</li>
                    </ul>
                    <div>
                        <p className="hoverTitle" style={{marginBottom:"0"}}>PRICING</p>
                    </div>
                    <ul>
                        <li>Planning fee</li>
                        <ul>
                            <li>One-time flat fee of $500</li>
                            <li>Rush (start date within 60 days) - $750</li>
                        </ul>
                        <li>Airline Ticketing and Logistics Management*</li>
                        <ul>
                            <li>$100 per person</li>
                            <li>Rush (start time within 60 days) - $150 per person<br/><i>*Fee charged at the time of ticketing</i></li>
                        </ul>
                    </ul>
                </div>
                <div className="offsitesPhotoContainer">
                    <Carousel width={"100%"}>
                        <div style={{maxWidth:"100%"}} className="offsitesPhoto">
                            <img style={{maxWidth:"100%"}} src={yoga}/>
                        </div>
                        <div style={{maxWidth:"100%"}} className="offsitesPhoto">
                            <img style={{maxWidth:"100%"}} src={Valley}/>
                        </div>
                        <div style={{maxWidth:"100%"}} className="offsitesPhoto">
                            <img style={{maxWidth:"100%"}} src={beach}/>
                        </div>
                        <div style={{maxWidth:"100%"}} className="offsitesPhoto">
                            <img style={{maxWidth:"100%"}} src={bridge2}/>
                        </div>
                        <div style={{maxWidth:"100%"}} className="offsitesPhoto">
                            <img  style={{maxWidth:"100%"}}src={indoorRetreat}/>
                        </div>
                        <div style={{maxWidth:"100%"}} className="offsitesPhoto">
                            <img  style={{maxWidth:"100%"}}src={stairs}/>
                        </div>
                    </Carousel>
                </div>
            </section>
            <section className="offsitesForm">
                <p className='formTitle'>Interest Form</p>
                    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.min.js"></script><iframe src="https://traveljoy.com/webforms/EDCg9HhpEbRJqnVAsinKnN8g/forms/3AEC8DvNQZCrKjr5D9mDKh6w" frameBorder="0" width="100%" height="600"></iframe><script type="text/javascript">{"iFrameResize({checkOrigin:false});"}</script>

            </section>
        </section>
    )

}