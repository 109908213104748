import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Link, useLocation } from "react-router-dom";


export default function Terms(props) {

    return(
        <div>
            <p>
                This is the terms page.
            </p>
        </div>
    )
}