import "./TravelSpecialties.css"

import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

import beach from "../../../Media/TravelSpecialites/Beach.jpg"
import couch from "../../../Media/TravelSpecialites/Couch.jpg"
import cozy from "../../../Media/TravelSpecialites/Cozy.jpg"
import drink from "../../../Media/TravelSpecialites/Drink.jpg"
import yoga from "../../../Media/TravelSpecialites/Yoga.jpg"

export default function Specialties(props) {

    return(
        <section id='specialties' className="specialtiesPage" >
            <div className="specPageIntro">
                <img src={couch}/>
                <div className="spiText">
                    <h1>TRAVEL SPECIALTIES</h1>
                </div>
            </div>
                <div id='immersion' className="specialtiesCard">
                    <div className="specialtiesContent">
                        <div className="specialtiesIntro">
                                    {/* light green, */}
                            <h1 className="specialtiesTitle">90-Day Immersion</h1>
                            <hr className="specLine"/>
                            <p style={{fontFamily:"Raleway",fontSize:"15px",margin:"0px"}}>Most countries allow a 90-day visa-free stay to anyone traveling on a US passport. It's the perfect amount of time to get to know one place in-depth. Travel Culture's immersion experiences are designed to enable you to connect with the places you visit in a way that will have a lasting positive impact on your life. Sometimes even following your regular routine can feel like an adventure if you're doing it in a new place.</p>
                        </div>
                        <div className="hoverContainer">
                            <div className="d">
                              {/* light green, */}
                                <div>
                                <p className="hoverTitle">IMAGINE...</p>
                                </div>
                                <ul className="specialtiesUl" style={{textAlign:"left"}}>
                                    <li>3 months of Italian classes for the whole family in the heart of Rome</li>
                                    <hr className="liLine"/>
                                    <li>Taking Zoom calls from your beachfront villa in Costa Rica</li>
                                    <hr className="liLine"/>
                                    <li>Arriving in Japan equipped with a national rail pass and a plan to set foot in <i>every</i> prefecture</li>
                                    <hr className="liLine"/>
                                    <li>Personally “researching” the French wine industry (for business purposes, of course)</li>
                                </ul>
                            </div>
                            <img className="specPic" src={cozy}/>
                        </div>
                    </div>
                </div>

                <div id='worldTour' className="specialtiesCard">
                    <div className="specialtiesContent">
                        <div className="hoverContainer">
                            <div className="d">
                              {/* light green, */}
                                <div>
                                <p className="hoverTitle">IMAGINE...</p>
                                </div>
                                <ul className="specialtiesUl" style={{textAlign:"left"}}>
                                    <li>Touring Europe by rail</li>
                                    <hr className="liLine"/>
                                    <li>Trying the street food in every Southeast Asian country</li>
                                    <hr className="liLine"/>
                                    <li>Island hopping through the Caribbean</li>
                                    <hr className="liLine"/>
                                    <li>Visiting every continent in one trip</li>
                                </ul>
                            </div>
                            <img className="specPic" src={beach}/>
                        </div>
                        <div className="specialtiesIntro" style={{paddingLeft:"60px",paddingRight:"0px"}}>
                                     {/* light green, */}
                            <h1 className="specialtiesTitle" style={{textAlign:"left"}}>World Tour</h1>
                            <hr className="rightLine"/>
                            <p style={{fontFamily:"Montserrat",fontSize:"15px",paddingRight:"0px",margin:"0px"}}>Do you have a travel bucket list? Do you just want to see and do it all? Has it been too long since your last big adventure? Even if you won’t be going all the way around the world, organizing multi-country itineraries on your own can be overwhelming. Travel Culture is there to take care of every little detail so that all you have to do is enjoy the moment. I hope you have plenty of pages left in your passport! </p>
                        </div>

                    </div>
                </div>
                <div  id='themedTrips'className="specialtiesCard">
                    <div className="specialtiesContent">
                        <div className="specialtiesIntro">
                                     {/* light green, */}
                            <h1 className="specialtiesTitle">Themed Trips</h1>
                            <hr className="specLine"/>
                            <p style={{fontFamily:"Montserrat",fontSize:"15px",margin:"0px"}}>Travel with the intention of seeing and/or doing things that are based around a special interest of yours. Themed trips make for some of the best stories. Experience things you've only read about, celebrate a new holiday, learn to Waltz in Vienna, scuba with sharks in the Seychelles, drink Champagne in Champagne… the possibilities are endless! I can't wait to hear what you're passionate about. </p>
                        </div>
                        <div className="hoverContainer">
                            <div className="d">
                              {/* light green, */}
                                <div>
                                <p className="hoverTitle">IMAGINE...</p>
                                </div>
                                <ul className="specialtiesUl" style={{textAlign:"left"}}>
                                    <li>Hiking through Middle-earth (New Zealand), dining in King's Landing (Croatia), or boarding the Hogwarts Express (Scotland)</li>
                                    <hr className="liLine"/>
                                    <li>Setting up your easel in the fields of Provence</li>
                                    <hr className="liLine"/>
                                    <li>Hitting the slopes (and I mean <i>THE</i> slopes, you know the ones)</li>
                                    <hr className="liLine"/>
                                    <li>Celebrating a special occasion in an actual castle (with falconry and archery and everything)</li>
                                </ul>
                            </div>
                            <img className="specPic" src={yoga}/>
                        </div>

                    </div>
                </div>
                <div id='surpriseMe' className="specialtiesCard">
                <div className="specialtiesContent">
                        <img className="specPic" src={drink}/>
                        <div className="specialtiesIntro" style={{paddingLeft:"60px",paddingRight:"0px"}}>
                                     {/* light green, */}
                            <h1 className="specialtiesTitle" style={{textAlign:"left"}}>Surprise Me</h1>
                            <hr className="rightLine"/>
                            <p style={{fontFamily:"Montserrat",fontSize:"15px",paddingRight:"0px",margin:"0px"}}>Don't know where you want to go? No problem. I'll get to know you and your travel style and surprise you with an itinerary that I think will be a great fit.</p>
                        </div>
                    </div>
                </div>
        </section>
    )
}